import React, { Component } from "react";
import "./Tachometer.scss";
import { connect } from "react-redux";
import { loadDataForProperty } from "../../redux/actions/device/device";
import leaf from "../../assets/img/gauge.svg";
import pointer from "../../assets/img/pointer.svg";

// @ts-ignore
import ReactSpeedometer from "react-d3-speedometer";

class Tachometer extends Component<
  { config: any; loadDataForProperty: any; data: any },
  { percent: any }
> {
  private defaultMAX = 100;
  state = {
    percent: 0
  };
  /**
   * Constructor
   * @param props
   */
  constructor(props: any) {
    super(props);
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this.props.loadDataForProperty(this.props.config.dataType);
  }

  /**
   * Render method.
   */
  render() {
    this.state.percent =
      (100 / (this.props.config.max - this.props.config.min)) *
      this.props.data.y;

    const pointerStyle = {
      transform: "rotate(" + (this.state.percent / 100) * 180 + "deg)"
    };

    return (
      <div className="tachometer-container">
        <div className="tachometer-header">{this.props.config.title}</div>
        <div className="tachometer-body">
          <div className="gauge-wrapper">
            <div className="gauge">
              <img className="leaf" src={leaf} />
              <img
                id="pointer"
                className="pointer"
                src={pointer}
                style={pointerStyle}
              />
            </div>
            <div className="labelPercentage">
              {this.props.config.unit == "%" &&
                (Math.round(this.state.percent) || 0) + this.props.config.unit}
              {this.props.config.unit !== "%" &&
                (Math.round(this.props.data.y) || 0) + this.props.config.unit}
            </div>
            <div className="labelMin">{this.props.config.min}</div>
            <div className="labelMax">{this.props.config.max}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: any) => {
  let data = [];
  if (state.device.data[props.config.dataType]) {
    data = state.device.data[props.config.dataType];
  }
  return {
    currentConfigTab: state.device.currentConfigTab,
    data: data
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadDataForProperty: (property: string) => {
      dispatch(loadDataForProperty(property));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tachometer);
