import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSortAlphaDown, faFolderOpen, faPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { loadHirarchy, pushGroup, popGroup, setAddToGroup } from '../../redux/actions/hirarchy/hirarchy';
import GroupItem from '../../components/GroupItem/GroupItem';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import DeviceItem from '../../components/DeviceItem/DeviceItem';
import GroupSettingsModal from '../../components/GroupSettingsModal/GroupSettingsModal';
import { logout } from '../../redux/actions/user/user';
import backIcon from '../../assets/icons/back.svg';
import DeviceSettingsModal from '../../components/DeviceSettingsModal/DeviceSettingsModal';

class HomePage extends Component<{ loadHirarchy: any, setAddToGroup: any, logout: any, popGroup: any, currentNode: any, loading: boolean, currentNodeIsRootNode: boolean, push: any }, {}> {
  state = {
    username: "",
    password: ""
  };

  componentDidMount() {
    this.props.loadHirarchy();
  }

  renderLoading() {
    return (
      <div>
        <div className="header-box">
          <div className="title">
          My Blox
          </div>
        </div>
        <div>
          <div className="p-20">
            <LoadingSpinner></LoadingSpinner>
          </div>
        </div>
      </div>
    );
  }

  sortGroupsAndGateways() {
    if (!(this.props.currentNode.groups && this.props.currentNode.groups.length > 0)) {
      return {
        gateways: [],
        groups: []
      };
    }

    let gateways: any[] = [];
    let groups: any[] = [];

    this.props.currentNode.groups.forEach((element: any) => {
      if (element.is_gateway) {
        gateways.push(element);
      } else {
        groups.push(element);
      }
    });

    return {
      gateways: gateways,
      groups: groups
    };
  }

  renderHirarchy() {
    let groupsAndGateways = this.sortGroupsAndGateways();

    return (
      <div>
        <div className="header-box">
          {this.props.currentNodeIsRootNode ? <div className="action-button action-button--left"></div> : <div className="action-button" onClick={() => {
            this.props.popGroup();
          }}>
            <img src={backIcon} />
          </div>}
          <div className="title">
            {this.props.currentNode.name}
          </div>
          {this.props.currentNodeIsRootNode ? <div className="action-button action-button--right" onClick={() => {
            this.props.logout();
          }}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </div> : <div className="action-button"></div>}
        </div>
        {(groupsAndGateways.groups.length > 0) && <div className="subtitle">Subgroups</div>}
        {
          groupsAndGateways.groups && groupsAndGateways.groups.map((group: any) => {
            return (
              <GroupItem group={group} key={group.name}></GroupItem>
            );
          })
        }
        {(groupsAndGateways.gateways.length > 0) && <div className="subtitle">Gateways</div>}
        {
          groupsAndGateways.gateways && groupsAndGateways.gateways.map((group: any) => {
            return (
              <GroupItem group={group} key={group.name}></GroupItem>
            );
          })
        }
        {(this.props.currentNode.devices && this.props.currentNode.devices.length > 0) && <div className="subtitle">Devices</div>}
        {
          this.props.currentNode.devices && this.props.currentNode.devices.map((device: any) => {
            return (
              <DeviceItem device={device} key={device.name}></DeviceItem>
            );
          })
        }
        <div className="p-20">
        <a href="#" className="button" onClick={() => {
          this.props.setAddToGroup(this.props.currentNode.uuid);
          this.props.push("addgroup");
        }}><FontAwesomeIcon icon={ faPlus } /> Add group here</a>
        </div>
        <GroupSettingsModal></GroupSettingsModal>
        <DeviceSettingsModal></DeviceSettingsModal>
      </div>
    );
  }

  render() {
    return (
      <div className="HomePage page">
        {this.props.loading ? this.renderLoading() : this.renderHirarchy()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.hirarchy.loading,
    currentNode: state.hirarchy.currentNode,
    currentNodeIsRootNode: state.hirarchy.currentNodeIsRootNode
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadHirarchy: () => {
      dispatch(loadHirarchy());
    },
    pushGroup: (group: any) => {
      dispatch(pushGroup(group));
    },
    popGroup: () => {
      dispatch(popGroup());
    },
    setAddToGroup: (groupId: any) => {
      dispatch(setAddToGroup(groupId));
    },
    push: (path: string, state: any) => {
      dispatch(push(path, state))
    },
    logout: () => {
      dispatch(logout());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
