import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateGroup } from '../../redux/actions/hirarchy/hirarchy';
import { push } from 'connected-react-router';
import ChartDiagramm from '../ChartDiagramm/ChartDiagramm';
import Tachometer from '../Tachometer/Tachometer';

class DeviceItemContentComposer extends Component<{ contentConfiguration: any, currentTimeInterval: any }, {}> {
    render() {
        return (
            <div>
                {
                    this.props.contentConfiguration.map((configuration: any, index: any) => {
                        if (configuration.type === "chart") {
                            return (
                                <div className="box mt-10" key={ configuration.title + configuration.to }>
                                    <ChartDiagramm config={ configuration } configurationIndex={ index } />
                                </div>
                            );
                        }
                        if (configuration.type === "tachometer") {
                            return (
                                <div className="box mt-10" key={ configuration.title + configuration.to }>
                                    <Tachometer config={ configuration } />
                                </div>
                            );
                        }
                        return (<div>Configuration error.</div>);
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    // Update configurations based on time interval change. TODO:
    let contentConfiguration = state.device.contentConfiguration;
    for (var i = 0; i < contentConfiguration.length; i++) {
        // Only overwrite config if it's not a custom query!
        if (!contentConfiguration[i].customQuery) {
            contentConfiguration[i].to = new Date();
            var from = new Date();
            from.setSeconds(from.getSeconds() - state.device.currentTimeInterval);
            contentConfiguration[i].from = from;
        }
    }

    return {
        contentConfiguration: contentConfiguration,
        currentTimeInterval: state.device.currentTimeInterval
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateGroup: (data: any, groupId: string) => {
            dispatch(updateGroup(data, groupId));
        },
        push: (route: any) => {
            dispatch(push(route));
        }
    };
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(DeviceItemContentComposer);
