import { HIRARCHY_LOADED, PUSH_GROUP, POP_GROUP, HIRARCHY_STARTED_LOADING, SET_ADD_TO_GROUP, GROUP_ADDED, ADDING_GROUP, UPDATING_GROUP, SHOW_GROUP_DETAILS, SHOW_DEVICE_DETAILS, UPDATING_DEVICE } from "../../actions/hirarchy/hirarchy";

/**
 * Helper to find a group in the stack.
 * @param group 
 * @param id 
 */
function findGroupWithId(group: any, groupId: any): any {
    if (group.uuid === groupId) {
        return group;
    }

    if (group.groups && group.groups.length > 0) {
        for (var i = 0; i < group.groups.length; i++) {
            let result = findGroupWithId(group.groups[i], groupId);
            if (result) {
                return result;
            }
        }
    }
    return null;
}

/**
 * Helper to update a group based on a given data object.
 * @param group 
 * @param id 
 */
function updateGroupWithData(group: any, targetGroupId: any, data: any): any {
    if (group.uuid === targetGroupId) {
        // Update data.
        var keys = Object.keys(data);
        for(var j = 0; j < keys.length; j++) {
            group[keys[j]] = data[keys[j]];
        }
    }

    if (group.groups && group.groups.length > 0) {
        for (var i = 0; i < group.groups.length; i++) {
            updateGroupWithData(group.groups[i], targetGroupId, data);
        }
    }

    return;
}

/**
 * Helper to update a device based on a given data object, throughout the whole tree.
 * @param group 
 * @param id 
 */
function updateDeviceWithData(group: any, targetDeviceId: any, data: any): any {
    let devicesForGroup = group.devices;
    if (devicesForGroup && devicesForGroup.length > 0) {
        for (var j = 0; j < devicesForGroup.length; j++) {
            if (group.devices[j].uuid === targetDeviceId) {
                // Update data.
                var keys = Object.keys(data);
                for(var j = 0; j < keys.length; j++) {
                    group.devices[j][keys[j]] = data[keys[j]];
                }
            }
        }
    }

    if (group.groups && group.groups.length > 0) {
        for (var i = 0; i < group.groups.length; i++) {
            updateDeviceWithData(group.groups[i], targetDeviceId, data);
        }
    }

    return;
}

/**
 * Helper to create a flat group list for flat device list.
 * @param group 
 */
function createFlatGroupListForDevices(group: any): any {
    let groupsArr: any[] = [];

    groupsArr.push({
        value: group.uuid,
        label: group.name,
        devices: group.devices
    });

    if (group.groups && group.groups.length > 0) {
        for (var i = 0; i < group.groups.length; i++) {

            let result = createFlatGroupListForDevices(group.groups[i]);
            if (result) {
                groupsArr.push(...result);
            }
        }
    }
    return groupsArr;
}

/**
 * Helper to create a flat group list.
 * @param group 
 */
function createFlatGroupList(group: any): any {
    let groupsArr: any[] = [];

    if (group.is_gateway) {
        return groupsArr;
    }

    groupsArr.push({
        value: group.uuid,
        label: group.name,
        devices: group.devices
    });

    if (group.groups && group.groups.length > 0) {
        for (var i = 0; i < group.groups.length; i++) {

            let result = createFlatGroupList(group.groups[i]);
            if (result) {
                groupsArr.push(...result);
            }
        }
    }
    return groupsArr;
}

/**
 * Helper to create a flat device list.
 * @param flatGroupList 
 */
function createFlatDeviceList(flatGroupList: any): any {
    let deviceList: any[] = [];

    for (var i = 0; i < flatGroupList.length; i++) {
        let group = flatGroupList[i];
        for (var ii = 0; ii < group.devices.length; ii++) {
            let device = group.devices[ii];
            if (device) {
                deviceList.push({
                    value: device.uuid,
                    label: device.name,
                });
            }
        }
    }

    deviceList = deviceList.filter((device: any, index, self) =>
        index === self.findIndex((t: any) => (
            t.uuid === device.uuid
        ))
    );

    return deviceList;
}

const initialState = {
    loading: true,
    dataMutationLoading: false,
    rootNode: {},
    currentNode: {},
    hirarchyStack: [],
    currentNodeIsRootNode: true,
    addGroupToGroupId: -1,
    flatGroupList: [],
    flatDeviceList: [],
    groupForEditModal: null,
    deviceForEditModal: null
};

export default function hirarchy(state = initialState, action: any) {
    if (action.type === SET_ADD_TO_GROUP) {
        return Object.assign({}, state, {
            addGroupToGroupId: action.groupId
        });
    }
    if (action.type === HIRARCHY_STARTED_LOADING) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === HIRARCHY_LOADED) {
        // Reload vs. first time.
        let currentNode = action.rootNode;
        let hirarchyStack = [action.rootNode.uuid];
        if (state.hirarchyStack.length > 0) {
            hirarchyStack = state.hirarchyStack;
            let currentNodeId = hirarchyStack[hirarchyStack.length - 1];
            currentNode = findGroupWithId(state.rootNode, currentNodeId);
        }

        // Create group list.
        let flatGroupList = createFlatGroupList(action.rootNode);
        let flatGroupListForDevices = createFlatGroupListForDevices(action.rootNode);
        let flatDeviceList = createFlatDeviceList(flatGroupListForDevices);

        // Check if we are at the root level.
        let currentNodeIsRootNode = false;
        if (hirarchyStack.length == 1) {
            currentNodeIsRootNode = true;
        }

        return Object.assign({}, state, {
            loading: false,
            rootNode: action.rootNode,
            currentNode: currentNode,
            hirarchyStack: hirarchyStack,
            currentNodeIsRootNode: currentNodeIsRootNode,
            addGroupToGroupId: action.rootNode.uuid,
            flatGroupList: flatGroupList,
            flatDeviceList: flatDeviceList
        });
    }
    if (action.type === ADDING_GROUP) {
        return Object.assign({}, state, {
            dataMutationLoading: true
        });
    }
    if (action.type === GROUP_ADDED) {
        return Object.assign({}, state, {
            dataMutationLoading: false
        });
    }
    if (action.type === SHOW_DEVICE_DETAILS) {
        return Object.assign({}, state, {
            deviceForEditModal: action.device
        });
    }
    if (action.type === SHOW_GROUP_DETAILS) {
        return Object.assign({}, state, {
            groupForEditModal: action.group
        });
    }
    if (action.type === PUSH_GROUP) {
        return Object.assign({}, state, {
            loading: false,
            currentNode: action.group,
            hirarchyStack: [...state.hirarchyStack, action.group.uuid],
            currentNodeIsRootNode: false
        });
    }
    if (action.type === POP_GROUP) {
        // Don't pop if we're at the root element.
        if (state.hirarchyStack.length <= 1) {
            return state;
        }

        // Pop top item and set new top item as currentNode.
        let hirarchyStack = [...state.hirarchyStack];
        hirarchyStack.pop();
        let currentNodeIsRootNode = false;
        if (hirarchyStack.length == 1) {
            currentNodeIsRootNode = true;
        }
        let currentNodeId = hirarchyStack[hirarchyStack.length - 1];
        let currentNode = findGroupWithId(state.rootNode, currentNodeId);
        return Object.assign({}, state, {
            loading: false,
            currentNode: currentNode,
            hirarchyStack: hirarchyStack,
            currentNodeIsRootNode
        });
    }
    if (action.type === UPDATING_GROUP) {
        // Update current node.
        let currentNode = Object.assign({}, state.currentNode);
        updateGroupWithData(currentNode, action.groupId, action.data);

        // Update root node.
        let rootNode = Object.assign({}, state.rootNode);
        updateGroupWithData(rootNode, action.groupId, action.data);
        
        return Object.assign({}, state, {
            loading: false,
            currentNode: currentNode,
            rootNode: rootNode
        });
    }
    if (action.type === UPDATING_DEVICE) {
        // Update current node.
        let currentNode = Object.assign({}, state.currentNode);
        updateDeviceWithData(currentNode, action.deviceId, action.data);

        // Update root node.
        let rootNode = Object.assign({}, state.rootNode);
        updateDeviceWithData(rootNode, action.deviceId, action.data);
        
        return Object.assign({}, state, {
            loading: false,
            currentNode: currentNode,
            rootNode: rootNode
        });
    }
    return state;
}  