import axios from 'axios';
import { Client } from '../../../services/client';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const userLoginStartedAction = (username: string, password: string) => {
  return {
    type: USER_LOGIN_STARTED,
    username,
    password
  }
}

export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const userLoginFailedAction = (error: string) => {
  return {
    type: USER_LOGIN_FAILED,
    error
  }
}

export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const userLoginSucceededAction = (auth: string) => {
  return {
    type: USER_LOGIN_SUCCEEDED,
    auth
  }
}

export const USER_REGISTER_STARTED = 'USER_REGISTER_STARTED';
export const userRegisterStartedAction = () => {
  return {
    type: USER_REGISTER_STARTED
  }
}

export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED';
export const userRegisterFailedAction = (error: string) => {
  return {
    type: USER_REGISTER_FAILED,
    error
  }
}

export const USER_REGISTER_SUCCEEDED = 'USER_REGISTER_SUCCEEDED';
export const userRegisterSucceededAction = (auth: string) => {
  return {
    type: USER_REGISTER_SUCCEEDED,
    auth
  }
}

export const logout = () => {
  return (dispatch: any) => {
    localStorage.removeItem("auth");
    setInterval(() => {
      dispatch(push("/"));
      location.reload();
    }, 500);
  }
}

export const performPasswordReset = (username: string) => {
  return (dispatch: any) => {
      /*dispatch(userLoginStartedAction(username, password));

      Client.login(username, password).then((data: any) => {
        dispatch(userLoginSucceededAction(data.token));
        dispatch(push("/overview"));
      }).catch((error: Error) => {
        dispatch(userLoginFailedAction("Username or password incorrect."));
      });*/
  }
}

export const performLogin = (username: string, password: string) => {
  return (dispatch: any) => {
      dispatch(userLoginStartedAction(username, password));

      Client.login(username, password).then((data: any) => {
        dispatch(userLoginSucceededAction(data.token));
        dispatch(push("/overview"));
      }).catch((error: Error) => {
        dispatch(userLoginFailedAction("Username or password incorrect."));
      });
  }
}

export const performRegister = (username: string, password: string, email: string) => {
  return (dispatch: any) => {
      dispatch(userRegisterStartedAction());

      Client.register(username, email, password).then((data: any) => {
        dispatch(userRegisterSucceededAction(data.token));
        dispatch(push("/"));
        Swal.fire({
          title: 'Account created',
          text: 'Your account has been created successfully!',
          type: 'success',
          confirmButtonColor: '#ed1c24',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      }).catch((error: Error) => {
        dispatch(userRegisterFailedAction("Registration failed."));
      });
  }
}