export default [{
    value: 1800,
    name: "30m"
}, {
    value: 3600,
    name: "1h"
}, {
    value: 86400,
    name: "24h"
}, {
    value: 604800,
    name: "7d"
}, {
    value: 2592000,
    name: "30d"
}, {
    value: 15552000,
    name: "6m"
}, {
    value: 31104000,
    name: "1y"
}];