import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './GroupSettingsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNetworkWired, faFolderOpen, faPen } from '@fortawesome/free-solid-svg-icons';
import { showGroupDetails, updateGroup, removeGroup } from '../../redux/actions/hirarchy/hirarchy';

class GroupSettingsModal extends Component<{ showModal: boolean, showGroupDetails: any, groupDetails: any, updateGroup: any, removeGroup: any }, { groupName: string }> {
    state = {
        groupName: ""
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.groupName === "" && this.state.groupName === "" && this.props.groupDetails.name) {
            this.setState({
                groupName: this.props.groupDetails.name
            });
        }
    }

    close() {
        this.setState({
            groupName: ""
        });
        this.props.showGroupDetails(null);
    }

    save() {
        if (!this.props.groupDetails.uuid || !this.state.groupName) {
            return;
        }

        this.props.updateGroup({
            name: this.state.groupName
        }, this.props.groupDetails.uuid);
        this.close();
    }

    remove() {
        if (!confirm("Are you sure you want to remove this group and all of it's child groups?")) {
            return;
        }

        this.props.removeGroup(this.props.groupDetails.uuid);
        this.close();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showModal}
                contentLabel="Choose time interval"
                ariaHideApp={false}
            >
                {this.props.groupDetails && <div className="modal">
                    <div className="modal-header">
                        <div className="btn btn--black">
                            <div className="icon">
                                {
                                    this.props.groupDetails.is_gateway ? <FontAwesomeIcon icon={faNetworkWired} /> : <FontAwesomeIcon icon={faFolderOpen} />
                                }
                            </div>
                        </div>
                        <div className="title">{this.props.groupDetails.name}</div>
                        <div className="btn" onClick={() => {
                            this.close();
                        }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="edit-container">
                            <div className="inputForm">
                                <span>Rename</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.groupName} onChange={(event: any) => {
                                        this.setState({ groupName: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                        </div>
                        <div className="doneBtn">
                            <a href="#" className="button" onClick={() => {
                                this.remove();
                            }}>Remove group</a>
                            <a href="#" className="button button--primary" onClick={() => {
                                this.save();
                            }}>Save</a>
                        </div>
                    </div>
                </div>}
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showModal: (state.hirarchy.groupForEditModal !== null),
        groupDetails: state.hirarchy.groupForEditModal,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeGroup: (groupId: string) => {
            dispatch(removeGroup(groupId));
        },
        updateGroup: (data: any, groupId: string) => {
            dispatch(updateGroup(data, groupId));
        },
        showGroupDetails: (config: any) => {
            dispatch(showGroupDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSettingsModal);
