import React, { Component } from 'react';
import logo from '../../assets/img/logo.png';
import logoshape from '../../assets/img/logoshape.png';
import { connect } from 'react-redux';
import { performLogin, performPasswordReset } from '../../redux/actions/user/user';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import ChartDiagramm from '../../components/ChartDiagramm/ChartDiagramm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

class ForgotPassword extends Component<{ performPasswordReset: any, loading: boolean, loginError: string, push: any }, { username: string }> {
  state = {
    username: ""
  };

  renderLoading() {
    return (
      <LoadingSpinner></LoadingSpinner>
    );
  }

  componentDidMount() {
    if (localStorage.getItem("auth")) {
      this.props.push("overview");
    }
  }

  resetPassword() {
    this.props.performPasswordReset(this.state.username);
  }
  
  renderLoginMask() {
    return (
      <div className="column">
        <div className="box">
          <div className="header">Reset Password</div>
          <div className="body">
            <div className="inputForm inputForm--underlined">
              <span>Username</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={ faUser } />
              <input type="text" value={this.state.username} onChange={(event: any) => {
                this.setState({ username: event.target.value });
              }}></input>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-container">
          {this.props.loginError && <div className="errorMessage">{this.props.loginError}</div>}
          <a href="#" className="button button--primary" onClick={() => {
            this.resetPassword();
          }}>Reset Password</a>
          <a className="button" href="/">Back to Login</a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="LoginFrontPage page">
        <img src={logoshape} className="logoshape" />
        <img src={logo} className="logo" />
        {this.props.loading ? this.renderLoading() : this.renderLoginMask()}
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    loading: state.user.loading,
    loginError: state.user.loginError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    performPasswordReset: (username: any) => {
      dispatch(performPasswordReset(username));
    },
    push: (location: string) => {
      dispatch(push(location));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
