import axios from 'axios';

export class Client {
    static baseURL = "http://p01.power-blox.cloud:8000/api/";
    // static baseURL = "http://localhost:8000/api/";

    static injectAuthHeader() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth");
    }

    static logout() {
        localStorage.removeItem("auth");
    }

    static login(username: string, password: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'login_check', {
                username,
                password
            }).then(function(response: any) {
                if (response.data && response.data.token) {
                    localStorage.setItem("auth", response.data.token);
                    Client.injectAuthHeader();
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static register(username: string, email: string, password: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'register', {
                username,
                password,
                email
            }).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static hirarchy() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'hirarchy').then(function(response: any) {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static alerts() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'alerts').then(function(response: any) {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static deleteAlert(alertId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'alerts/' + alertId).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static saveAlert(data: any) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'alerts', data).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static addGroupToGroup(groupName: string, groupId: any) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'groups/' + groupId + '/addgroup', {
                name: groupName
            }).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static addBlockToGroup(macAddress: string, groupId: string, blockName: string) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseURL + 'groups/' + groupId + '/addblock', {
                macAddress: macAddress,
                name: blockName
            }).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static loadDeviceInfo(deviceId: any) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'device/' + deviceId).then(function(response: any) {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static loadDeviceDataForProperty(deviceId: any, property: any) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseURL + 'device/' + deviceId + "/live?type=chart&property=" + property).then(function(response: any) {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static loadDeviceHistoryForProperty(deviceId: any, property: any, from: Date, to: Date) {
        return new Promise((resolve, reject) => {
            let fromTimestamp = (from.getTime() / 1000).toFixed(0);
            let toTimestamp = (to.getTime() / 1000).toFixed(0);
            axios.get(this.baseURL + 'device/' + deviceId + "/history?type=chart&property=" + property + "&from=" + fromTimestamp + "&to=" + toTimestamp).then(function(response: any) {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static removeDevice(deviceId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'device/' + deviceId).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static removeGroup(groupId: any) {
        return new Promise((resolve, reject) => {
            axios.delete(this.baseURL + 'groups/' + groupId).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static updateGroup(data: any, groupId: any) {
        return new Promise((resolve, reject) => {
            axios.put(this.baseURL + 'groups/' + groupId, data).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }

    static updateDevice(data: any, deviceId: any) {
        return new Promise((resolve, reject) => {
            axios.put(this.baseURL + 'device/' + deviceId, data).then(function(response: any) {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(null);
                }
            }).catch(function(error) {
                reject(error);
            });
        });
    }
}