import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './AddAlertModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNetworkWired, faPen, faDatabase, faMagic, faCubes, faCube } from '@fortawesome/free-solid-svg-icons';
import { showAddAlertModal, saveAlert } from '../../redux/actions/alert/alert';
import Select from 'react-select';
import DataFieldOptions from '../CustomConfigModal/DataFieldOptions';
import LimitOperations from './LimitOperations';

const initialState = {
    name: "New Alert",
    dataField: DataFieldOptions[0].value,
    limitLogic: LimitOperations[0].value,
    limitValue: "0",
    customAlertText: "",
    deviceId: "",
    groupId: "",
    dataFieldVal: DataFieldOptions[0],
    limitLogicVal: LimitOperations[0],
    deviceIdVal: null,
    groupIdVal: null
};

class AddAlertModal extends Component<{ showModal: boolean, showAddAlertModal: any, flatDeviceList: any[], flatGroupList: any[], saveAlert: any }, {}> {
    state = initialState;
    
    /**
     * Close
     */
    close() {
        this.setState(initialState);
        this.props.showAddAlertModal(null);
    }

    /**
     * Save
     */
    save() {
        this.props.saveAlert({
            name: this.state.name,
            dataField: this.state.dataField,
            limitLogic: this.state.limitLogic,
            limitValue: this.state.limitValue,
            customAlertText: this.state.customAlertText,
            deviceId: this.state.deviceId,
            groupId: this.state.groupId
        });
        this.close();
    }
    
    /**
     * Main render function
     */
    render() {
        return (
            <Modal
                isOpen={this.props.showModal}
                contentLabel="Custom configuration modal"
                ariaHideApp={false}
            >
                <div className="modal">
                    <div className="modal-header">
                        <div className="btn btn--black">
                            <div className="icon">
                                <FontAwesomeIcon icon={faNetworkWired} />
                            </div>
                        </div>
                        <div className="title">{this.state.name}</div>
                        <div className="btn" onClick={() => {
                            this.close();
                        }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="edit-container">
                            <div className="inputForm inputForm--lined">
                                <span>Rename</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.name} onChange={(event: any) => {
                                        this.setState({ name: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Choose Data Field</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={ faDatabase } />
                                    <Select
                                        onChange={(selectedOption) => {
                                            this.setState({
                                                dataFieldVal: selectedOption,
                                                dataField: (selectedOption as any).value
                                            });
                                        }}
                                        value={this.state.dataFieldVal}
                                        options={DataFieldOptions}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          border: 'none',
                                          colors: {
                                          ...theme.colors,
                                            primary25: '#eee',
                                            primary: '#ed1c24',
                                          },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Choose Limit Logic</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={ faMagic } />
                                    <Select
                                        onChange={(selectedOption) => {
                                            this.setState({
                                                limitLogicVal: selectedOption,
                                                limitLogic: (selectedOption as any).value
                                            });
                                        }}
                                        value={this.state.limitLogicVal}
                                        options={LimitOperations}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          border: 'none',
                                          colors: {
                                          ...theme.colors,
                                            primary25: '#eee',
                                            primary: '#ed1c24',
                                          },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Set Limit Value</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.limitValue} onChange={(event: any) => {
                                        this.setState({ limitValue: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            <div className="inputForm inputForm--lined">
                                <span>Set Custom Alert Text</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.customAlertText} onChange={(event: any) => {
                                        this.setState({ customAlertText: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                            { this.state.groupId === "" && <div className="inputForm inputForm--lined">
                                <span>Apply to Single Device</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={ faCube } />
                                    <Select
                                        onChange={(selectedOption) => {
                                            this.setState({
                                                deviceIdVal: selectedOption,
                                                deviceId: (selectedOption as any).value
                                            });
                                        }}
                                        value={this.state.deviceIdVal}
                                        options={this.props.flatDeviceList}
                                        theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        border: 'none',
                                        colors: {
                                        ...theme.colors,
                                            primary25: '#eee',
                                            primary: '#ed1c24',
                                        },
                                        })}
                                    />
                                </div>
                            </div> }
                            { this.state.deviceId === "" && <div className="inputForm inputForm--lined">
                                <span>Apply to Group</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={ faCubes } />
                                <Select
                                    onChange={(selectedOption) => {
                                        this.setState({
                                            groupIdVal: selectedOption,
                                            groupId: (selectedOption as any).value
                                        });
                                    }}
                                    value={this.state.groupIdVal}
                                    options={this.props.flatGroupList}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      border: 'none',
                                      colors: {
                                      ...theme.colors,
                                        primary25: '#eee',
                                        primary: '#ed1c24',
                                      },
                                    })}
                                />
                                </div>
                            </div> }
                        </div>
                        <div className="doneBtn mt-0 pt-0">
                            <a href="#" className="button button--primary" onClick={() => {
                                this.save();
                            }}>Save</a>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showModal: (state.alert.addAlertModal !== null),
        flatGroupList: state.hirarchy.flatGroupList,
        flatDeviceList: state.hirarchy.flatDeviceList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showAddAlertModal: (data: any) => {
            dispatch(showAddAlertModal(data));
        },
        saveAlert: (data: any) => {
            dispatch(saveAlert(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAlertModal);
