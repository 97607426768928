import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { faFolderOpen, faPlus, faArrowLeft, faBoxes } from '@fortawesome/free-solid-svg-icons';
import { addGroupToGroup } from '../../redux/actions/hirarchy/hirarchy';
import backIcon from '../../assets/icons/back.svg';

class AddGroup extends Component<{ push: any, addGroupToGroup: any }, {}> {
  state = {
    name: ""
  };

  /**
   * Main render method
   */
  render() {
    return (
      <div className="page">
        <div className="header-box">
          <div className="action-button" onClick={() => {
            this.props.push("overview");
          }}>
            <img src={backIcon} />
          </div>
          <div className="title">
            Add Group
          </div>
          <div className="action-button"></div>
        </div>
        <div className="box">
          <div className="body">
            <div className="inputForm">
              <span>Groupname</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={faBoxes} />
                <input type="text" value={this.state.name} onChange={(event: any) => {
                  this.setState({ name: event.target.value });
                }}></input>
              </div>
            </div>
            <div className="pb-20 pt-10">
              <a href="#" className="button button--primary" onClick={() => {
                if (this.state.name && this.state.name.length > 0) {
                  this.props.addGroupToGroup(this.state.name);
                }
              }}><FontAwesomeIcon icon={faPlus} /> Add group</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {

  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    push: (path: any) => {
      dispatch(push(path));
    },
    addGroupToGroup: (groupName: string) => {
      dispatch(addGroupToGroup(groupName));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddGroup);
