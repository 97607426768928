import { faTachometerAlt, faChartBar, faWrench } from '@fortawesome/free-solid-svg-icons';

export default [{
    value: "current",
    name: "CURRENT",
    icon: faTachometerAlt,
    configuration: [{
        type: "tachometer",
        title: "Battery Charge State",
        dataType: "ctl_charge_percentage",
        unit: "%",
        min: 0,
        max: 100
    }, {
        type: "tachometer",
        title: "Battery Voltage",
        dataType: "ctl_voltage_battery_measured",
        unit: "V",
        min: 0,
        max: 3000
    }]
}, {
    value: "stats",
    name: "STATS",
    icon: faChartBar,
    configuration: [{
        type: "chart",
        title: "Battery Charge State",
        dataType: "ctl_charge_percentage",
        unit: "%",
        from: new Date(),
        to: new Date(),
        min: 0,
        max: 100
    }]
}, {
    value: "custom",
    name: "CUSTOM",
    icon: faWrench,
    configuration: []
}];