import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as classnames from 'classnames';
import { push } from 'connected-react-router';
import './Footer.scss';

import powerblock from '../../assets/icons/powerblock.svg';
import plus from '../../assets/icons/plus.svg';
import bell from '../../assets/icons/bell.svg';
import powerblockActive from '../../assets/icons/powerblock_active.svg';
import plusActive from '../../assets/icons/plus_active.svg';
import bellActive from '../../assets/icons/bell_active.svg';
import logo from '../../assets/img/logo.png';

class Footer extends Component<{ currentRoute: any, push: any }, { }> {
    /**
     * Weather to show footer or not.
     */
    showFooter() {
        if (this.props.currentRoute === '/') {
            return false;
        }
        if (this.props.currentRoute === '/register') {
            return false;
        }
        if (this.props.currentRoute === '/forgotpassword') {
            return false;
        }

        return true;
    }

    /**
     * Check weather or not current page is add device menu.
     */
    isAddDevice() {
        return this.props.currentRoute === '/adddevice';
    }

    /**
     * Check weather or not current page is my blox menu.
     */
    isMyBlox() {
        return this.props.currentRoute === '/overview' || this.props.currentRoute.startsWith("/device/");
    }

    /**
     * Check weather or not current page is alerts.
     */
    isAlerts() {
        return this.props.currentRoute === '/alerts';
    }

    /**
     * Main route method
     */
    render() {
        if (!this.showFooter()) {
            return (<div></div>);
        }

        return (
            <div className="footer">
                <img className="footer-logo" src={ logo } />
                <div onClick={() => {
                    this.props.push("/adddevice");
                }} className={classnames(
                'footer-item',
                {
                    'active': this.isAddDevice()
                }
                )}>
                    <img src={ this.isAddDevice() ? plusActive : plus } />
                    <div>ADD BLOX</div>
                </div>
                <div onClick={() => {
                    this.props.push("/overview");
                }} className={classnames(
                'footer-item',
                {
                    'active': this.isMyBlox()
                }
                )}>
                    <img src={ this.isMyBlox() ? powerblockActive : powerblock } />
                    <div>MY BLOX</div>
                </div>
                <div onClick={() => {
                    this.props.push("/alerts");
                }} className={classnames(
                'footer-item',
                {
                    'active': this.isAlerts()
                }
                )}>
                    <img src={ this.isAlerts() ? bellActive : bell } />
                    <div>MY ALERTS</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentRoute: state.router.location.pathname
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        push(route: any) {
            dispatch(push(route));
        }
    };
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(Footer);
