import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import './DeviceSettingsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNetworkWired, faFolderOpen, faPen, faCube } from '@fortawesome/free-solid-svg-icons';
import { showGroupDetails, updateGroup, removeDevice, showDeviceDetails, updateDevice } from '../../redux/actions/hirarchy/hirarchy';

class DeviceSettingsModal extends Component<{ showModal: boolean, showDeviceDetails: any, deviceDetails: any, updateDevice: any, removeDevice: any }, { deviceName: string }> {
    state = {
        deviceName: ""
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.deviceName === "" && this.state.deviceName === "" && this.props.deviceDetails.name) {
            this.setState({
                deviceName: this.props.deviceDetails.name
            });
        }
    }

    close() {
        this.setState({
            deviceName: ""
        });
        this.props.showDeviceDetails(null);
    }

    save() {
        if (!this.props.deviceDetails.uuid || !this.state.deviceName) {
            return;
        }

        this.props.updateDevice({
            name: this.state.deviceName
        }, this.props.deviceDetails.uuid);
        this.close();
    }

    remove() {
        if (!confirm("Are you sure you want to remove this device?")) {
            return;
        }

        this.props.removeDevice(this.props.deviceDetails.uuid);
        this.close();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showModal}
                contentLabel="Update device"
                ariaHideApp={false}
            >
                {this.props.deviceDetails && <div className="modal">
                    <div className="modal-header">
                        <div className="btn btn--black">
                            <div className="icon">
                                <FontAwesomeIcon icon={ faCube } />
                            </div>
                        </div>
                        <div className="title">{this.props.deviceDetails.name}</div>
                        <div className="btn" onClick={() => {
                            this.close();
                        }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="edit-container">
                            <div className="inputForm">
                                <span>Rename</span>
                                <div className="inputForm-container">
                                    <FontAwesomeIcon icon={faPen} />
                                    <input type="text" value={this.state.deviceName} onChange={(event: any) => {
                                        this.setState({ deviceName: event.target.value });
                                    }}></input>
                                </div>
                            </div>
                        </div>
                        <div className="doneBtn">
                            <a href="#" className="button" onClick={() => {
                                this.remove();
                            }}>Remove device</a>
                            <a href="#" className="button button--primary" onClick={() => {
                                this.save();
                            }}>Save</a>
                        </div>
                    </div>
                </div>}
            </Modal>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        showModal: (state.hirarchy.deviceForEditModal !== null),
        deviceDetails: state.hirarchy.deviceForEditModal,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeDevice: (deviceId: string) => {
            dispatch(removeDevice(deviceId));
        },
        updateDevice: (data: any, deviceId: string) => {
            dispatch(updateDevice(data, deviceId));
        },
        showDeviceDetails: (config: any) => {
            dispatch(showDeviceDetails(config));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceSettingsModal);
