import React, { Component } from 'react';
import logo from '../../assets/img/logo.png';
import logoshape from '../../assets/img/logoshape.png';
import { connect } from 'react-redux';
import { performLogin, performRegister } from '../../redux/actions/user/user';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import ChartDiagramm from '../../components/ChartDiagramm/ChartDiagramm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';

class RegisterPage extends Component<{ performRegister: any, loadingRegister: boolean, registerError: string, push: any }, { username: string, password: string, email: string }> {
  state = {
    username: "",
    email: "",
    password: ""
  };

  renderLoading() {
    return (
      <LoadingSpinner></LoadingSpinner>
    );
  }

  register() {
    this.props.performRegister(this.state.username, this.state.password, this.state.email);
  }

  renderLoginMask() {
    return (
      <div className="column">
        <div className="box">
          <div className="header">Register</div>
          <div className="body">
            <div className="inputForm inputForm--underlined">
              <span>Username</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={ faUser } />
              <input type="text" value={this.state.username} onChange={(event: any) => {
                this.setState({ username: event.target.value });
              }}></input>
              </div>
            </div>
            <div className="inputForm inputForm--underlined">
              <span>Email</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={ faEnvelope } />
              <input type="text" value={this.state.email} onChange={(event: any) => {
                this.setState({ email: event.target.value });
              }}></input>
              </div>
            </div>
            <div className="inputForm inputForm--underlined">
              <span>Password</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={ faLock } />
              <input type="password" value={this.state.password} onChange={(event: any) => {
                this.setState({ password: event.target.value });
              }} onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.register();
                }
              }}></input>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-container">
          {this.props.registerError && <div className="errorMessage">{this.props.registerError}</div>}
          <a href="#" className="button button--primary" onClick={() => {
            this.register();
          }}>Register</a>
          <a className="button" href="/">Back to Login</a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="LoginFrontPage page">
        <img src={logoshape} className="logoshape" />
        <img src={logo} className="logo" />
        {this.props.loadingRegister ? this.renderLoading() : this.renderLoginMask()}
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return {
    loadingRegister: state.user.loadingRegister,
    registerError: state.user.registerError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    performRegister: (username: any, password: any, email: any) => {
      dispatch(performRegister(username, password, email));
    },
    push: (location: string) => {
      dispatch(push(location));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
