import { Client } from "../../../services/client";
import { push } from "connected-react-router";
import { logout } from "../user/user";
import Swal from "sweetalert2";

export const HIRARCHY_STARTED_LOADING = 'HIRARCHY_STARTED_LOADING';
export const hirarchyStartedLoading = () => {
  return {
    type: HIRARCHY_STARTED_LOADING
  }
}

export const HIRARCHY_LOADED = 'HIRARCHY_LOADED';
export const hirarchyLoaded = (rootNode: any) => {
  return {
    type: HIRARCHY_LOADED,
    rootNode
  }
}

export const PUSH_GROUP = 'PUSH_GROUP';
export const pushGroup = (group: any) => {
  return {
    type: PUSH_GROUP,
    group
  }
}

export const POP_GROUP = 'POP_GROUP';
export const popGroup = () => {
  return {
    type: POP_GROUP
  }
}

export const UPDATING_GROUP = 'UPDATING_GROUP';
export const updatingGroup = (data: any, groupId: string) => {
  return {
    type: UPDATING_GROUP,
    data,
    groupId
  }
}

export const GROUP_UPDATED = 'GROUP_UPDATED';
export const groupUpdated = (data: any, groupId: string) => {
  return {
    type: GROUP_UPDATED,
    data,
    groupId
  }
}

export const UPDATING_DEVICE = 'UPDATING_DEVICE';
export const updatingDevice = (data: any, deviceId: string) => {
  return {
    type: UPDATING_DEVICE,
    data,
    deviceId
  }
}

export const DEVICE_UPDATED = 'DEVICE_UPDATED';
export const deviceUpdated = (data: any, deviceId: string) => {
  return {
    type: DEVICE_UPDATED,
    data,
    deviceId
  }
}

export const ADDING_GROUP = 'ADDING_GROUP';
export const addingGroup = () => {
  return {
    type: ADDING_GROUP
  }
}

export const GROUP_ADDED = 'GROUP_ADDED';
export const groupAdded = (group: any, addToGroupWithId: any) => {
  return {
    type: GROUP_ADDED,
    group,
    addToGroupWithId
  }
}

export const ADDING_DEVICE = 'ADDING_DEVICE';
export const addingDevice = () => {
  return {
    type: ADDING_DEVICE
  }
}

export const DEVICE_ADDED = 'DEVICE_ADDED';
export const deviceAdded = (device: any, addToGroupWithId: any) => {
  return {
    type: DEVICE_ADDED,
    device,
    addToGroupWithId
  }
}

export const SET_ADD_TO_GROUP = 'SET_ADD_TO_GROUP';
export const setAddToGroup = (groupId: any) => {
  return {
    type: SET_ADD_TO_GROUP,
    groupId
  }
}

export const SHOW_DEVICE_DETAILS = 'SHOW_DEVICE_DETAILS';
export const showDeviceDetails = (device: any) => {
  return {
    type: SHOW_DEVICE_DETAILS,
    device
  }
}

export const SHOW_GROUP_DETAILS = 'SHOW_GROUP_DETAILS';
export const showGroupDetails = (group: any) => {
  return {
    type: SHOW_GROUP_DETAILS,
    group
  }
}

export const loadHirarchy = () => {
  return (dispatch: any) => {
      dispatch(hirarchyStartedLoading());
      
      Client.hirarchy().then((data: any) => {
        if (data && data.rootNode) {
          dispatch(hirarchyLoaded(data.rootNode));
        }
      }).catch((error: any) => {
        if (error && error.response && error.response.data && error.response.data.code === 401) {
          dispatch(logout());
        }
      });
  }
}

export const addGroupToGroup = (data: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(addingGroup());
    let addToGroupWithId = getState().hirarchy.addGroupToGroupId;

    Client.addGroupToGroup(data, addToGroupWithId).then((group) => {
      dispatch(groupAdded(group, addToGroupWithId));
      dispatch(loadHirarchy());
      dispatch(push("overview"));
    }).catch((error: Error) => {

    });
  }
}

export const addBlockToGroup = (macAddress: string, groupId: string, blockName: string) => {
  return (dispatch: any) => {
    dispatch(addingDevice());

    Client.addBlockToGroup(macAddress, groupId, blockName).then((device) => {
      dispatch(deviceAdded(device, groupId));
      dispatch(loadHirarchy());
      dispatch(push("overview"));
    }).catch((error: Error) => {
      Swal.fire({
        title: 'Device not found',
        text: 'The device could not be found. Please make sure that the device is turned on and already sending data to the cloud!',
        type: 'error',
        confirmButtonColor: '#ed1c24',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    });
  }
}

export const updateGroup = (data: any, groupId: string) => {
  return (dispatch: any) => {
    dispatch(updatingGroup(data, groupId));

    Client.updateGroup(data, groupId).then((group: any) => {
      dispatch(groupUpdated(data, groupId));
    }).catch((error: Error) => {

    });
  }
}

export const updateDevice = (data: any, deviceId: string) => {
  return (dispatch: any) => {
    dispatch(updatingDevice(data, deviceId));

    Client.updateDevice(data, deviceId).then((device: any) => {
      dispatch(deviceUpdated(data, deviceId));
    }).catch((error: Error) => {

    });
  }
}

export const removeDevice = (deviceId: string) => {
  return (dispatch: any) => {
    dispatch(hirarchyStartedLoading());
    Client.removeDevice(deviceId).then((device: any) => {
      window.location.reload();
    }).catch((error: Error) => {

    });
  }
}

export const removeGroup = (groupId: string) => {
  return (dispatch: any) => {
    dispatch(hirarchyStartedLoading());
    Client.removeGroup(groupId).then((group: any) => {
      window.location.reload();
    }).catch((error: Error) => {

    });
  }
}