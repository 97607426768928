import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pushGroup, updateGroup, showGroupDetails } from '../../redux/actions/hirarchy/hirarchy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faCog, faPen, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import dotsIcon from '../../assets/icons/dots.svg';
import * as classnames from 'classnames';

class GroupItem extends Component<{ group: any, pushGroup: any, updateGroup: any, showGroupDetails: any }, { toggleOptions: boolean }> {
    render() {
        return (
            <div className="box" onClick={() => {
                this.props.pushGroup(this.props.group);
            }}>
                <div className="row">
                    <div className="icon">
                        {
                            this.props.group.is_gateway ? <FontAwesomeIcon icon={ faNetworkWired } /> : <FontAwesomeIcon icon={ faFolderOpen } />
                        }
                    </div>
                    <div className="title">
                        { this.props.group.name }
                    </div>
                    <div className={classnames({
                        "action-button": true
                    })} onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.props.showGroupDetails(this.props.group);
                    }}>
                        <img className="more-icon" src={ dotsIcon } />
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateGroup: (data: any, groupId: string) => {
            dispatch(updateGroup(data, groupId));
        },
        pushGroup: (group: any) => {
            dispatch(pushGroup(group));
        },
        showGroupDetails: (config: any) => {
            dispatch(showGroupDetails(config));
        }
    };
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(GroupItem);
