import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faSync, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { loadDeviceInfo, addCustomConfig } from '../../redux/actions/device/device';
import LoadingSpinner from '../../components/Loading/Loading';
import { push } from 'connected-react-router';
import DeviceItemContentComposer from '../../components/DeviceItemContentComposer/DeviceItemContentComposer';
import DeviceConfigFilter from '../../components/DeviceConfigFilter/DeviceConfigFilter';
import DateRangeModal from '../../components/DateRangeModal/DateRangeModal';
import CustomConfigModal from '../../components/CustomConfigModal/CustomConfigModal';
import backIcon from '../../assets/icons/back.svg';
import refreshIcon from '../../assets/icons/refresh.svg';

class DeviceInfo extends Component<{ match: any, addCustomConfig: any, loadDeviceInfo: any, customConfigIndex: any, push: any, currentDevice: any, currentConfigTab: string, loading: boolean }, {}> {
  componentDidMount() {
    // Initially load data.
    this.loadData();

    // Reload data after 15 minutes.
    setInterval(() => {
      this.loadData();
    }, 1000 * 60 * 15);
  }

  /**
   * Load data
   */
  loadData() {
    if (this.props.match.params) {
      this.props.loadDeviceInfo(this.props.match.params.id);
    }
  }

  /**
   * Renders a loading component.
   */
  renderLoading() {
    return (
      <div className="p-20">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  }

  /**
   * Render for custom query add button.
   */
  renderAddCustomQuery() {
    if (this.props.currentConfigTab !== "custom") {
      return (<div></div>);
    }
    
    return (<div className="p-10">
      <a href="#" className="button button--primary" onClick={() => {
        this.props.addCustomConfig();
      }}>Add custom query</a>
    </div>);
  }

  /**
   * Renders the main content block of a item.
  */
  renderContent() {
    return (
      <div className="device-detail">
        <DeviceConfigFilter></DeviceConfigFilter>
        <DeviceItemContentComposer></DeviceItemContentComposer>
        { this.renderAddCustomQuery() }
      </div>
    );
  }
  
  /**
   * Main render method.
   */
  render() {
    return (
      <div className="DevicePage page">
        <div className="header-box">
            <div className="action-button action-button--left" onClick={() => {
                this.props.push("/overview");
            }}>
              <img src={ backIcon } />
            </div>
            <div className="title">
              { this.props.currentDevice ? this.props.currentDevice.name : "Loading..." }
            </div>
            <div className="action-button action-button--right" onClick={() => {
              this.loadData();
            }}>
              <img src={ refreshIcon } />
            </div>
        </div>
        {
          this.props.loading ? this.renderLoading() : this.renderContent()
        }
        <DateRangeModal></DateRangeModal>
        <CustomConfigModal></CustomConfigModal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loading: state.device.loading,
    currentDevice: state.device.currentDevice,
    currentConfigTab: state.device.currentConfigTab
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadDeviceInfo: (deviceId: any) => {
      dispatch(loadDeviceInfo(deviceId));
    },
    push: (path: string, state: any) => {
      dispatch(push(path, state))
    },
    addCustomConfig: () => {
      dispatch(addCustomConfig());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceInfo);
