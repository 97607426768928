import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import "./CustomConfigModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faNetworkWired,
  faFolderOpen,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import {
  showGroupDetails,
  updateGroup
} from "../../redux/actions/hirarchy/hirarchy";
import {
  setCustomConfigurationIndex,
  editCustomConfig,
  loadCustomContentConfiguration,
  deleteCustomConfig
} from "../../redux/actions/device/device";
import Select from "react-select";
import DataFieldOptions from "./DataFieldOptions";
import "react-dates/initialize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomConfigurationHelper } from "../../services/customConfigurationHelper";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class CustomConfigModal extends Component<
  {
    showModal: boolean;
    deleteCustomConfig: any;
    loadCustomContentConfiguration: any;
    editCustomConfig: any;
    setCustomConfigurationIndex: any;
    customConfigurationIndex: any;
  },
  { selectedType: any; startDate: any; endDate: any; loadedData: boolean }
> {
  state = {
    selectedType: DataFieldOptions[0],
    startDate: new Date(),
    endDate: new Date(),
    loadedData: false
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !prevState.loadedData &&
      !this.state.loadedData &&
      this.props.customConfigurationIndex !== null
    ) {
      let customDeviceConfigurationArray = CustomConfigurationHelper.get();
      let currentConfigToEdit =
        customDeviceConfigurationArray[this.props.customConfigurationIndex];
      this.setState({
        loadedData: true,
        selectedType: {
          label: currentConfigToEdit.title,
          value: currentConfigToEdit.dataType,
          unit: currentConfigToEdit.unit
        },
        startDate: new Date(currentConfigToEdit.from),
        endDate: new Date(currentConfigToEdit.to)
      });
    }
  }

  /**
   * Close
   */
  close() {
    this.setState({
      loadedData: false
    });
    this.props.setCustomConfigurationIndex(null);
  }

  /**
   * Save
   */
  save() {
    this.props.editCustomConfig(this.props.customConfigurationIndex, {
      title: this.state.selectedType.label,
      dataType: this.state.selectedType.value,
      unit: this.state.selectedType.unit,
      from: this.state.startDate,
      to: this.state.endDate
    });
    this.props.loadCustomContentConfiguration();
    this.close();
  }

  /**
   * Delete
   */
  delete() {
    this.props.deleteCustomConfig(this.props.customConfigurationIndex);
    this.props.loadCustomContentConfiguration();
    this.close();
  }

  /**
   * Main render function
   */
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel="Custom configuration modal"
        ariaHideApp={false}
      >
        <div className="modal">
          <div className="modal-header">
            <div className="btn btn--black">
              <div className="icon">
                <FontAwesomeIcon icon={faNetworkWired} />
              </div>
            </div>
            <div className="title">{this.state.selectedType.label}</div>
            <div
              className="btn"
              onClick={() => {
                this.close();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
          <div className="modal-body">
            <div className="edit-container">
              <div className="inputForm">
                <span>Data Field:</span>
                <div className="inputForm-container">
                  <FontAwesomeIcon icon={faPen} />
                  <Select
                    onChange={selectedOption => {
                      this.setState({
                        selectedType: selectedOption
                      });
                    }}
                    value={this.state.selectedType}
                    options={DataFieldOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      border: 'none',
                      colors: {
                      ...theme.colors,
                        primary25: '#eee',
                        primary: '#ed1c24',
                      },
                    })}
                  />
                </div>
              </div>
              <div className="inputForm mt-10">
                <span>Date:</span>
                <div className="inputForm-container">
                  <FontAwesomeIcon icon={faPen} />
                  <BrowserView>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date: any) => {
                        this.setState({
                          startDate: date
                        });
                      }}
                    />
                  </BrowserView>
                  <MobileView>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date: any) => {
                        this.setState({
                          startDate: date
                        });
                      }}
                      withPortal
                    />
                  </MobileView>
                  <FontAwesomeIcon icon={faPen} />
                  <BrowserView>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date: any) => {
                        this.setState({
                          endDate: date
                        });
                      }}
                    />
                  </BrowserView>
                  <MobileView>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={(date: any) => {
                        this.setState({
                          endDate: date
                        });
                      }}
                      withPortal
                    />
                  </MobileView>
                </div>
              </div>
            </div>
            <div className="doneBtn">
              <a
                href="#"
                className="button button--primary"
                onClick={() => {
                  this.save();
                }}
              >
                Save
              </a>
              <a
                href="#"
                className="button"
                onClick={() => {
                  this.delete();
                }}
              >
                Delete custom query
              </a>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    customConfigurationIndex: state.device.customConfigIndex,
    showModal: state.device.customConfigIndex !== null
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setCustomConfigurationIndex: (index: any) => {
      dispatch(setCustomConfigurationIndex(index));
    },
    showGroupDetails: (config: any) => {
      dispatch(showGroupDetails(config));
    },
    editCustomConfig: (index: any, data: any) => {
      dispatch(editCustomConfig(index, data));
    },
    deleteCustomConfig: (index: any) => {
      dispatch(deleteCustomConfig(index));
    },
    loadCustomContentConfiguration: () => {
      dispatch(loadCustomContentConfiguration());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomConfigModal);
