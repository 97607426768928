export default [{
    value: "ctl_voltage_battery_measured",
    label: "Voltage Battery Measured",
    unit: "mV"
}, {
    value: "ctl_voltage_battery_fastmeasured",
    label: "Voltage Battery Fastmeasured",
    unit: "mV"
}, {
    value: "ctl_power_charge",
    label: "Power Charge",
    unit: "mA"
}, {
    value: "ctl_power_discharge",
    label: "Power Discharge",
    unit: "mA"
}, {
    value: "ctl_power_fastcharge",
    label: "Power Fastcharge",
    unit: "mV"
}, {
    value: "ctl_power_fastdischarge",
    label: "Power Fastdischarge",
    unit: "mV"
}, {
    value: "ctl_temperature_battery",
    label: "Temperature Battery",
    unit: "°C"
}, {
    value: "ctl_temperature_print",
    label: "Temperature Print",
    unit: "°C"
}, {
    value: "ctl_charge_percentage",
    label: "Charge Percentage",
    unit: "%"
}, {
    value: "ctl_charge_led",
    label: "Charge Led",
    unit: "%"
}, {
    value: "mpp_voltage_solarpanel",
    label: "MPP Voltage Solarpanel",
    unit: "mV"
}, {
    value: "mpp_temperature",
    label: "MPP Temperature",
    unit: "°C"
}, {
    value: "inv_power_effective",
    label: "INV Power Effective",
    unit: ""
}, {
    value: "inv_temperature",
    label: "INV Temperature",
    unit: "°C"
}, {
    value: "inv_inverter_frequency",
    label: "Power Charge",
    unit: "Hz"
}, {
    value: "chg_power_expected",
    label: "Power Expected",
    unit: "V"
}, {
    value: "chg_voltage_expected",
    label: "Voltage Expected",
    unit: "V"
}];