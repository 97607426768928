import React, { Component } from "react";
import { connect } from 'react-redux';
import bell from '../../assets/icons/bell.svg';
import './AlertItem.scss';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteAlert } from "../../redux/actions/alert/alert";

class AlertItem extends Component<{ alert: any, deleteAlert: any }, {}> {

    /**
     * Main render function
     */
    render() {
        return (
            <div className="alert-item">
                <img className="icon" src={bell} />
                <div className="title">{this.props.alert.name}</div>
                <div className="action" onClick={() => {
                    this.props.deleteAlert(this.props.alert.uuid);
                }}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteAlert: (alertId: any) => {
            dispatch(deleteAlert(alertId));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertItem);
