import { ALERTS_LOADED, LOAD_ALERTS, SHOW_ADD_ALERT_MODAL } from "../../actions/alert/alert";

const initialState = {
    loading: true,
    alerts: [],
    addAlertModal: null
};

export default function alert(state = initialState, action: any) {
    if (action.type === LOAD_ALERTS) {
        return Object.assign({}, state, {
            loading: true
        });
    }
    if (action.type === ALERTS_LOADED) {
        return Object.assign({}, state, {
            loading: false,
            alerts: action.alerts
        });
    }
    if (action.type === SHOW_ADD_ALERT_MODAL) {
        return Object.assign({}, state, {
            addAlertModal: action.addAlertModal
        });
    }
    return state;
}  