import { Client } from "../../../services/client";
import { CustomConfigurationHelper } from "../../../services/customConfigurationHelper";
import DataFieldOptions from "../../../components/CustomConfigModal/DataFieldOptions";

export const SET_DEVICE_TIMEINTERVAL = 'SET_DEVICE_TIMEINTERVAL';
export const setDeviceTimeinterval = (currentTimeInterval: any) => {
  return {
    type: SET_DEVICE_TIMEINTERVAL,
    currentTimeInterval
  }
}

export const SET_DEVICE_CONTENTCONFIGURATION = 'SET_DEVICE_CONTENTCONFIGURATION';
export const setContentConfiguration = (contentConfiguration: any) => {
  return {
    type: SET_DEVICE_CONTENTCONFIGURATION,
    contentConfiguration
  }
}

export const SET_DEVICE_CURRENT_CONFIG_TAB = 'SET_DEVICE_CURRENT_CONFIG_TAB';
export const setCurrentConfigTab = (tab: any) => {
  return {
    type: SET_DEVICE_CURRENT_CONFIG_TAB,
    tab
  }
}

export const LOADING_DEVICE = 'LOADING_DEVICE';
export const loadingDevice = () => {
  return {
    type: LOADING_DEVICE
  }
}

export const DEVICE_LOADED = 'DEVICE_LOADED';
export const deviceLoaded = (device: any) => {
  return {
    type: DEVICE_LOADED,
    device
  }
}

export const LOADED_DATA_FOR_PROPERTY = 'LOADED_DATA_FOR_PROPERTY';
export const loadedDataForProperty = (property: string, data: any) => {
  return {
    type: LOADED_DATA_FOR_PROPERTY,
    property,
    data
  }
}

export const LOADED_HISTORY_DATA_FOR_PROPERTY = 'LOADED_HISTORY_DATA_FOR_PROPERTY';
export const loadedHistoryDataForProperty = (property: string, data: any) => {
  return {
    type: LOADED_HISTORY_DATA_FOR_PROPERTY,
    property,
    data
  }
}

export const SHOW_DATE_PICKER_MODAL = 'SHOW_DATE_PICKER_MODAL';
export const showDatePickerModal = (config: any) => {
  return {
    type: SHOW_DATE_PICKER_MODAL,
    config
  }
}

export const SET_CUSTOM_CONFIGURATION = 'SET_CUSTOM_CONFIGURATION';
export const setCustomConfigurationIndex = (configIndex: any) => {
  return {
    type: SET_CUSTOM_CONFIGURATION,
    configIndex
  }
}

export const loadCustomContentConfiguration = () => {
  return (dispatch: any, getState: any) => {
    dispatch(setContentConfiguration(CustomConfigurationHelper.get()));
  }
}

export const addCustomConfig = () => {
  return (dispatch: any, getState: any) => {
    let customDeviceConfigurationArray = CustomConfigurationHelper.get();
    customDeviceConfigurationArray.push({
      title: DataFieldOptions[0].label,
      dataType: DataFieldOptions[0].value,
      unit: DataFieldOptions[0].unit,
      type: "chart",
      customQuery: true,
      from: new Date(),
      to: new Date(),
      min: 0,
      max: 100
    });
    CustomConfigurationHelper.save(customDeviceConfigurationArray);
    let newIndex = customDeviceConfigurationArray.length - 1;
    dispatch(setCustomConfigurationIndex(newIndex));
  }
}

export const editCustomConfig = (index: any, data: any) => {
  return (dispatch: any, getState: any) => {
    let customDeviceConfigurationArray = CustomConfigurationHelper.get();
    let oldObj = customDeviceConfigurationArray[index];
    customDeviceConfigurationArray[index] = Object.assign({}, oldObj, data)
    CustomConfigurationHelper.save(customDeviceConfigurationArray);
  }
}

export const deleteCustomConfig = (index: any) => {
  return (dispatch: any, getState: any) => {
    let customDeviceConfigurationArray = CustomConfigurationHelper.get();
    customDeviceConfigurationArray.splice(index, 1);
    CustomConfigurationHelper.save(customDeviceConfigurationArray);
  }
}

export const loadDeviceInfo = (deviceId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingDevice());

    Client.loadDeviceInfo(deviceId).then((device) => {
      dispatch(deviceLoaded(device));
    }).catch((error: Error) => {
      dispatch(deviceLoaded(null));
    });
  }
}

export const loadDeviceHistoryForProperty = (property: string, from: Date, to: Date) => {
  return (dispatch: any, getState: any) => {
    let device = getState().device.currentDevice;
    if (device) {
      Client.loadDeviceHistoryForProperty(device.uuid, property, from, to).then((data: any) => {
        dispatch(loadedHistoryDataForProperty(property, data));
      }).catch((error: Error) => {

      });
    }
  }
}

export const loadDataForProperty = (property: string) => {
  return (dispatch: any, getState: any) => {
    let device = getState().device.currentDevice;
    if (device) {
      Client.loadDeviceDataForProperty(device.uuid, property).then((data: any) => {
        dispatch(loadedDataForProperty(property, data));
      }).catch((error: Error) => {

      });
    }
  }
}