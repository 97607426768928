import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classnames from 'classnames';
import { setCurrentConfigTab, setContentConfiguration, loadCustomContentConfiguration } from '../../redux/actions/device/device';
import configurationButtons from './DeviceConfigFilterConfig';

class DeviceConfigFilter extends Component<{ currentConfigTab: any, loadCustomContentConfiguration: any, setCurrentConfigTab: any, setContentConfiguration: any }, {}> {
    render() {
        return (
            <div className="tabbar mt-10 ml-10 mr-10">
            {
                configurationButtons.map((btn, index) => {
                    return (<div key={ index } className={classnames({
                        "tabbar-button": true,
                        "tabbar-button--active": (this.props.currentConfigTab === btn.value)
                    })} onClick={() => {
                        this.props.setCurrentConfigTab(btn.value);
                        if (btn.value === "custom") {
                            this.props.loadCustomContentConfiguration();
                        } else {
                            this.props.setContentConfiguration(btn.configuration);
                        }
                    }}>
                        <FontAwesomeIcon icon={ btn.icon } /> 
                        { btn.name }
                    </div>);
                })
            }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentConfigTab: state.device.currentConfigTab
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCurrentConfigTab: (tab: string) => {
            dispatch(setCurrentConfigTab(tab));
        },
        setContentConfiguration: (configuration: any) => {
            dispatch(setContentConfiguration(configuration));
        },
        loadCustomContentConfiguration: () => {
            dispatch(loadCustomContentConfiguration());
        }
    };
};

export default connect(
mapStateToProps,
mapDispatchToProps
)(DeviceConfigFilter);
