export class CustomConfigurationHelper {
    /**
     * Returns the current custom configuration array.
     */
    static get() {
        let customDeviceConfiguration = localStorage.getItem("customDeviceConfiguration");
        if (!customDeviceConfiguration) {
            customDeviceConfiguration = "[]";
        }
        let customDeviceConfigurationArray = JSON.parse(customDeviceConfiguration);
        return customDeviceConfigurationArray;
    }

    /**
     * Saves the current custom configuration array.
     */
    static save(data: any) {
        localStorage.setItem("customDeviceConfiguration", JSON.stringify(data));
    }
}