import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import QrReader from 'react-qr-reader';
import Select from 'react-select';
import { faQrcode, faCheck, faPen, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { addGroupToGroup, addBlockToGroup, loadHirarchy } from '../../redux/actions/hirarchy/hirarchy';

class AddDevice extends Component<{ push: any, addBlockToGroup: any, loadHirarchy: any, flatGroupList: any[], location: any }, {}> {
  state = {
    macAddress: "",
    name: "",
    groupToAddTo: null,
    cameraDidError: false
  };

  componentDidMount() {
    this.props.loadHirarchy();

    if (this.props.location.state && this.props.location.state.selectedGroup) {
      this.setState({
        groupToAddTo: this.props.location.state.selectedGroup
      });
    }
  }

  render() {
    return (
      <div className="HomePage page">
        <div className="header-box">
          <div className="title">
            Add new Blox
            </div>
        </div>

        {
          (!this.state.cameraDidError && this.state.macAddress === "") && <div className="box">
            <div className="body">
              <div className="inputForm">
                <QrReader
                  delay={300}
                  onError={(error) => {
                    this.setState({
                      cameraDidError: true
                    });
                  }}
                  onScan={(data: any) => {
                    if (data) {
                      this.setState({
                        macAddress: data
                      });
                    }
                  }}
                  style={{ width: '100%' }}
                />
                <span>SCAN THE QR CODE ON THE BACKSIDE OF YOUR POWER-BLOX</span>
              </div>
            </div>
          </div>
        }

        <div className="box mt-15">
          <div className="body">
            <div className="inputForm">
              <span>OR ENTER MAC ADDRESS MANUALLY:</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={faPen} />
                <input type="text" value={this.state.macAddress} onChange={(event: any) => {
                  this.setState({ macAddress: event.target.value });
                }}></input>
              </div>
            </div>
          </div>
        </div>

        <div className="header-box mt-15">
          <div className="title">
            NAME AND GROUP
            </div>
        </div>

        <div className="box">
          <div className="body">
            <div className="inputForm">
              <span>NAME YOUR NEW POWER-BLOX:</span>
              <div className="inputForm-container">
                <FontAwesomeIcon icon={faPen} />
                <input type="text" value={this.state.name} onChange={(event: any) => {
                  this.setState({ name: event.target.value });
                }}></input>
              </div>
            </div>
          </div>
        </div>

        <div className="box mt-5">
          <div className="body">
            <div className="inputForm inputFormSelect">
              <span>QUICKADD IT TO A GROUP:</span>
              <Select
                onChange={(selectedOption) => {
                  this.setState({
                    groupToAddTo: selectedOption
                  });
                }}
                value={this.state.groupToAddTo}
                options={this.props.flatGroupList}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  border: 'none',
                  colors: {
                  ...theme.colors,
                    primary25: '#eee',
                    primary: '#ed1c24',
                  },
                })}
              />
            </div>
          </div>
        </div>

        <div className="p-20 pt-10">
          <a href="#" className="button button--primary" onClick={() => {
            let groupId = null;
            if (this.state.groupToAddTo) {
              groupId = (this.state.groupToAddTo as any).value;
            }
            let name = this.state.name;
            let macAddress = this.state.macAddress;
            if (groupId && name && name.length > 0 && macAddress && macAddress.length > 0) {
              this.props.addBlockToGroup(macAddress, groupId, name);
            }
          }}><FontAwesomeIcon icon={faCheck} /> ADD POWER-BLOX TO MY BLOX</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    flatGroupList: state.hirarchy.flatGroupList
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    push: (route: any) => {
      dispatch(push(route));
    },
    loadHirarchy: () => {
      dispatch(loadHirarchy());
    },
    addBlockToGroup: (macAddress: string, groupIdToAddTo: string, blockName: string) => {
      dispatch(addBlockToGroup(macAddress, groupIdToAddTo, blockName));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDevice);
